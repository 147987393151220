<template>
<li class="dropdown-card">
  <button @click="isOpen = !isOpen" class="dropdown-card__button">
    <span class="dropdown-card__title">{{content.question}}</span>
    <Icon :id="icon" class="dropdown-card__icon"/>
  </button>
  <DropDown :is-open="isOpen">
    <RawHtml :html="content.answer" class="dropdown-card__content"/>
  </DropDown>
</li>
</template>

<script>
import {Icon, DropDown, RawHtml} from '@/components/global'

export default {
  name: "DropdownCard",
  data() {
    return {
      isOpen: false
    }
  },
  components: {
    DropDown,
    Icon,
    RawHtml
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      return this.isOpen ? 'minus' : 'plus'
    }
  }
}
</script>

