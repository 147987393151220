<template>
<section  class="section --cta">
  <img :src="content.image" alt="" class="section__cta-image">
  <div class="section__cta">
    <TitleBlock :content="content.titleBlock"/>
    <Button v-if="content.route || content.link" :route="content.route ?? null" :link="content.link ?? null" :label="content.button"/>
  </div>
</section>
</template>

<script>
import {Button} from '@/components/small'
import TitleBlock from "@/components/blocks/TitleBlock";

export default {
  name: "CTASection",
  components: {Button, TitleBlock},
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
