<template>
  <Navbar/>
  <router-view v-slot="{ Component }">
    <Transition name="fade">
      <component :is="Component"/>
    </Transition>
  </router-view>
  <Footer v-if="$beet.isReady"/>
  <Gdpr :options="{backgroundColor: '#faf9f4'}"/>
</template>
<script>
import {Footer} from '@/components/sections'
import {Navbar} from '@/components/navigation'
import {useLangRouter} from "@/extentions/langRouter";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'app',
  components: {
    Gdpr,
    Navbar,
    Footer
  },
  setup() {
    useLangRouter()
  },
  watch: {
    '$i18n.locale': {
      handler: function (to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    }
  },
  mounted() {
    window.setTimeout(() => {
      if (this.$route.hash) document.getElementById((this.$route.hash).replace('#', '')).scrollIntoView({behavior: 'smooth'})
    }, 1000)
  }
}
</script>
