<template>
  <LangRouterLink v-if="route" class="button" :to="route">
    <span>{{label}}</span>
    <Icon id="arrow"/>
  </LangRouterLink>
  <a v-else class="button" :href="link" target="_blank">
    <span>{{label}}</span>
    <Icon id="arrow"/>
  </a>
</template>

<script>
import {Icon, LangRouterLink} from "@/components/global";

export default {
  name: "Button",
  components: {
    Icon,
    LangRouterLink
  },
  props: {
    label: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    },
    route: {
      type: Object,
      required: false
    }
  }
}
</script>
