<template>
  <a :href="content.link" class="address">
    <img :src="marker" alt="" class="address__marker"/>
    <p>{{`${content.address}`}}</p>
    <p>{{`${content.postalCode} ${content.city}, ${content.state}`}}</p>
  </a>
</template>

<script>
export default {
  name: 'AddressLink',
  data() {
    return {
      marker: require('@/assets/icons/marker.svg')
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
