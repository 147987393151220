import { createStore } from 'vuex'

export default createStore({
  state: {
    siteMap: {
      links: [
        'hotel',
        'rooms',
        'packages',
        'services',
        'contact'
      ]
    }
  },
  getters: {
    getSiteMap(state) {
      return state.siteMap
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
