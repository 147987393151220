<template>
  <Transition name="fade">
    <section :class="['modal', {'--visible': isOpen}]">
      <button class="modal__button --close" @click="closeModal">
        <Icon id="hamburger-opened"/>
      </button>
      <div class="modal__image-container">
        <img class="modal__image" :src="images[currentImage]" alt="" ref="mainImage">
        <button class="modal__button --image --left" @click="swipeLeft">
          <Icon id="horizontal-arrow"/>
        </button>
        <button class="modal__button --image" @click="swipeRight">
          <Icon id="horizontal-arrow"/>
        </button>
      </div>
      <div class="modal__bottom">
        <div class="modal__preview">
          <div class="modal__preview-list">
            <img v-for="(image, imageKey) in images" :key="imageKey" :src="image"
                 alt="" :class="['modal__preview-image', {'--active': imageKey === currentImage}]"
                 @click="changeCurrentImage(imageKey)">
          </div>
        </div>
        <button class="modal__button --bottom" @click="swipeRight">
          <Icon id="horizontal-arrow"/>
        </button>
      </div>
    </section>
  </Transition>
</template>

<script>
import {Icon} from "@/components/global";

export default {
  name: "ImagesModal",
  data() {
    return {
      currentImage: 0,
      isOpen: false,
      swiperInitialPosition: 0
    }
  },
  components: {Icon},
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  methods: {
    changeCurrentImage(newIndex) {
      this.$refs.mainImage.style.opacity = '0'
      window.setTimeout(() => {
        this.currentImage = newIndex
        this.$refs.mainImage.style.opacity = '1'
      }, 200)
    },
    closeModal() {
      this.isOpen = false
      this.currentImage = 0
    },
    swipeLeft() {
      const newIndex = this.currentImage - 1 >= 0 ? this.currentImage - 1 : this.images.length - 1
      this.changeCurrentImage(newIndex)
    },
    swipeRight() {
      const newIndex = this.currentImage + 1 < this.images.length ? this.currentImage + 1 : 0
      this.changeCurrentImage(newIndex)
    }
  },
  watch: {
    images() {
      this.isOpen = true
    },
    isOpen() {
      this.isOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    }
  },
  mounted() {
    this.$refs.mainImage.addEventListener('touchstart', (event) => {
      this.swiperInitialPosition = event.touches[0].clientX
    })
    this.$refs.mainImage.addEventListener('touchend', (event) => {
      if (this.swiperInitialPosition > event.changedTouches[0].clientX) {
        this.swipeRight()
      } else {
        this.swipeLeft()
      }
    })
  }
}
</script>
