import './startup'

import {createApp} from 'vue'
import mitt from 'mitt';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'
import langRouter from '@/extentions/langRouter'
import scroll from '@/extentions/scroll'

import '@/styles/app.scss'

const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .use(langRouter, 'fr')
  .use(GlobalComponents)
  .use(BeetAPI, {store, apiUrl: process.env.VUE_APP_API_URL})
  .use(scroll, {
    class: '',
    duration: 800,
    scale: 1,
    distance: '10px',
    mobile: false
  })

const emitter = mitt();
app.provide('emitter', emitter)

import { createGtm } from '@gtm-support/vue-gtm';

app.use(
  createGtm({
    id: 'GTM-58L3RT8',
    defer: true,
    enabled: false,
    vueRouter: router,
  }),
);

app.mount('#app')
