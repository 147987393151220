<template>
  <button @click="$i18n.locale = otherLang.toLowerCase()" class="navbar__toggler">
    <span :class="{'--inactive': !isCurrentlyFR}">FR</span>
    <span>/</span>
    <span :class="{'--inactive': isCurrentlyFR}">EN</span>
  </button>
</template>

<script>
export default {
  name: "LangToggler",
  computed: {
    isCurrentlyFR() {
      return this.$i18n.locale === 'fr'
    },
    otherLang() {
      return this.isCurrentlyFR ? 'EN' : 'FR'
    },
  }
}
</script>

<style scoped>

</style>
