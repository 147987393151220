const _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
                target[key] = source[key];
            }
        }
    }
    return target;
};

const sr = require('scrollreveal').default();

function generateOptions(defaultOptions, bindingValue, bindingModifiers) {
    const options = _extends({}, defaultOptions, bindingValue);

    if (bindingModifiers) {
        if (bindingModifiers.reset) {
            options.reset = true;
        }

        if (bindingModifiers.nomobile) {
            options.mobile = false;
        }

        if (bindingModifiers.nodesktop) {
            options.desktop = false;
        }
    }

    return options;
}

export default {
    install: (Vue, defaultOptions) => {
        Vue.directive('scroll-reveal', {
            mounted: (el, binding) => {
                const options = generateOptions(defaultOptions, binding.value, binding.modifiers);

                if (typeof options.class === 'string') {
                    el.classList.add(options.class);
                    delete options.class;
                }

                sr.reveal(el, options);
            },
            updated: (el, binding) => {
                if (binding.value != binding.oldValue) {
                    var options = generateOptions(defaultOptions, binding.value, binding.modifiers);
                    sr.reveal(el, options);
                }
            }
        });

        Vue.config.globalProperties.$sr = {
            isSupported() {
                return sr.isSupported();
            },
            sync() {
                sr.sync();
            },
            reveal(target, config, interval, sync) {
                sr.reveal(target, config, interval, sync);
            }
        }
    }
}
