<template>
  <div class="navbar__desktop">
    <HomeLogoLink />
    <ul class="navbar__desktop-links">
      <li v-for="(link, linkKey) in siteMap" :key="linkKey">
        <LangRouterLink :to="{name: link}" v-if="link !== 'contact'" class="navbar__desktop-link">{{ $t(`global.navigation.${link}`) }}</LangRouterLink>
        <LangRouterLink v-if="link === 'contact'" :to="{name: $route.name ?? 'home', hash: '#contact'}" class="navbar__desktop-link --contact">{{ $t(`global.navigation.${link}`) }}</LangRouterLink>
      </li>
    </ul>
    <div class="navbar__right">
      <Button :link="reservationLink" :label="$t('global.reserve')"/>
      <LangToggler />
    </div>
  </div>
</template>

<script>
import {LangRouterLink} from "@/components/global";
import {Button, HomeLogoLink, LangToggler} from '@/components/small'

export default {
  name: 'DesktopNavbar',
  components: {Button, HomeLogoLink, LangRouterLink, LangToggler},
  props: {
    siteMap: {
      required: true
    }
  },
  computed: {
    reservationLink() {
      return this.$beet.isReady ? this.$beet.options.contact.reservationUrl.toString() : ''
    }
  }
}
</script>
