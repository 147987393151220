<template>
  <div class="detailed-dropdown">
    <button @click="isOpen = !isOpen" class="detailed-dropdown__button">
      <span>{{detailsLabel}}</span>
      <Icon :id="icon" class="detailed-dropdown__icon"/>
    </button>
    <DropDown :is-open="isOpen">
      <RawHtml :html="content" class="detailed-dropdown__content"/>
    </DropDown>
  </div>
</template>

<script>
import {DropDown, Icon, RawHtml} from '@/components/global'

export default {
  name: "DetailsDropdown",
  props: {
    content: {
      type: String,
      required: true
    },
    detailsLabel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  components: {
    DropDown,
    Icon,
    RawHtml
  },
  computed: {
    icon() {
      return this.isOpen ? 'minus' : 'plus'
    }
  }
}
</script>

