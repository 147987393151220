<template>
  <section class="section --faq">
    <p class="--center">{{content.text}}</p>
    <ul class="dropdown-card__group">
      <DropdownCard v-for="(question, questionKey) in content.questions" :key="questionKey" :content="question" />
    </ul>
  </section>
</template>

<script>
import {DropdownCard} from "@/components/cards";

export default {
  name: "DropdownCardsSection",
  components: {
    DropdownCard
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
