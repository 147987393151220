<template>
  <div class="navbar__mobile">
    <div class="navbar__mobile-top">
      <HomeLogoLink/>
      <button @click="isOpen = !isOpen" class="navbar__mobile-toggle">
        <Icon :id="iconId"/>
      </button>
    </div>
    <DropDown :is-open="isOpen">
      <div class="navbar__mobile-content">
        <ul class="navbar__mobile-links">
          <li class="navbar__mobile-home">
            <LangRouterLink :to="{name: 'home'}">{{ $t(`global.navigation.home`) }}</LangRouterLink>
          </li>
          <li v-for="(link, linkKey) in siteMap" :key="linkKey">
            <LangRouterLink :to="{name: link}" v-if="link !== 'contact'">{{
                $t(`global.navigation.${link}`)
              }}
            </LangRouterLink>
            <a v-if="link === 'contact'" href="#contact">{{ $t(`global.navigation.${link}`) }}</a>
          </li>
        </ul>
        <Button :link="reservationLink" :label="$t('global.reserve')" class="--top"/>
        <LangToggler class="--mobile"/>
      </div>
    </DropDown>
  </div>
</template>

<script>
import {Button, HomeLogoLink, LangToggler} from "@/components/small";
import {DropDown, Icon, LangRouterLink} from '@/components/global'

export default {
  name: "MobileMenu",
  components: {Button, DropDown, HomeLogoLink, Icon, LangRouterLink, LangToggler},
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    iconId() {
      return this.isOpen ? 'hamburger-opened' : 'hamburger-closed'
    },
    reservationLink() {
      return this.$beet.isReady ? this.$beet.options.contact.reservationUrl.toString() : ''
    }
  },
  props: {
    siteMap: {
      required: true
    }
  },
  methods: {
    handleResize() {
      if (this.isOpen && document.body.clientWidth > 1024) {
        this.isOpen = false
      }
    }
  },
  watch: {
    isOpen() {
      this.isOpen ? document.body.classList.add('--lock') : document.body.classList.remove('--lock')
    },
    '$route'() {
      this.isOpen = false
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  }
}
</script>
