<template>
  <section :class="['room-card__section', {'--dark': isDark}]">
    <TitleBlock v-if="titleBlock" :content="titleBlock" class="--center"/>
    <ul class="room-card__list">
      <RoomCard v-for="(room, roomKey) in rooms" :key="roomKey" :content="room" :labels="labels"/>
    </ul>
  </section>
</template>

<script>
import {TitleBlock} from "@/components/blocks";
import {RoomCard} from "@/components/cards";
export default {
  name: "RoomCardsSection",
  components: {RoomCard, TitleBlock},
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      required: true
    },
    rooms: {
      type: Array,
      required: true
    },
    titleBlock: {
      type: Object,
      default: null
    }
  },
  emits: ['openModal']
}
</script>

