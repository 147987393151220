<template>
  <section class="section__question">
    <img :src="icon" alt="" class="section__question-icon">
    <h2 class="title --smaller">{{title}}</h2>
    <p>{{content}}</p>
    <Button :route="{name: 'faq'}" :label="button" class="--question"/>
  </section>
</template>

<script>
import {Button} from '@/components/small'

export default {
  name: "Question",
  components: {Button},
  data() {
    return {
      button: this.$beet.options.faq.buttonLabel,
      content: this.$beet.options.faq.content,
      icon: require('@/assets/icons/texture-beige.svg'),
      title: this.$beet.options.faq.title
    }
  }
}
</script>
