import {createRouter, createWebHistory} from 'vue-router'
import {generateRoutes} from '@/extentions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
  {
    path: '/faq',
    paths: {en: '/faq'},
    name: 'faq',
    component: () => import('@/views/FAQ')
  },
  {
    path: '/',
    paths: {en: '/'},
    name: 'home',
    component: () => import('@/views/Home')
  },
  {
    path: '/hotel',
    paths: {en: '/hotel'},
    name: 'hotel',
    component: () => import('@/views/Hotel')
  },
  {
    path: '/forfaits-et-offres',
    paths: {en: '/packages'},
    name: 'packages',
    component: () => import('@/views/Packages')
  },
  {
    path: '/chambres-et-suites',
    paths: {en: '/rooms'},
    name: 'rooms',
    component: () => import('@/views/Rooms')
  },
  {
    path: '/services',
    paths: {en: '/services'},
    name: 'services',
    component: () => import('@/views/Services')
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'catch-all',
    component: NotFound
  }
]

const localizedRoutes = generateRoutes(routes, 'fr', ['en'])

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: '-active',
  linkExactActiveClass: '-exact-active',
  routes: localizedRoutes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth"
      }
    }
    return { top: 0, behavior: 'smooth' }
  }
})

export default router
