<template>
  <section class="section__promo" >
    <p>{{content.text}}</p>
    <p class="section__promo-special">{{content.special}}</p>
  </section>
</template>

<script>
export default {
  name: "PromoSection",
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

