<template>
  <LangRouterLink :to="{name: 'home'}">
    <img :src="siteLogo" alt="" class="navbar__logo">
  </LangRouterLink>
</template>

<script>
import {LangRouterLink} from "@/components/global";
export default {
  name: "HomeLogoLink",
  components: {
    LangRouterLink
  },
  computed: {
    siteLogo() {
      return require('@/assets/logos/hotel.png')
    }
  }
}
</script>
