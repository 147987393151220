export default {
  "faq": {
    "title": "Foire aux questions",
    "subtitle": "Trouvez les réponses à vos questions"
  },
  "footer": {
    "contact": "Coordonnées",
    "follow": "Suivez-nous",
    "copyright": "Copyright Hôtel Boutique Ophelia, 2022 - Tous droits Réservés",
    "design": "Conception et développement par <b>Beet</b>"
  },
  "global": {
    "navigation": {
      "contact": "Contact",
      "home": "Accueil",
      "hotel": "Hôtel",
      "packages": "Forfaits et offres",
      "rooms": "Chambres et suites",
      "services": "Services",
    },
    "price": "À partir de PRICE$ la nuit",
    "reserve": "Réserver"
  },
  "home": {
    "VisitTheWebsite": "Visiter le site"
  },
  "notFound": {
    "return": 'Retour à l\'accueil',
    "subtitle": "La page que vous recherchez n’existe pas. Elle a peut-être été déplacée ou complètement supprimée. Retournez à la page d’accueil ou explorez les onglets de la navigation principale.",
    "title": 'Erreur 404'
  },
  "packages": {
    "offer": "Promotion",
    "package": "Forfait"
  }
}
