<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="relative footer__map">
        <div :style="`background-image: url('${map}')`" class="footer__map-image"></div>
        <div class="footer__address">
          <RightAddressLink :content="contact.address" class="footer__address-panel --footer" />
          <img :src="marker" alt="" class="footer__marker">
        </div>
      </div>
      <div class="footer__contact" id="contact">
        <div>
          <h2 class="footer__title">{{$t("footer.contact")}}</h2>
            <div class="footer__links">
              <a :href="contact.address.link" class="footer__link" target="_blank">
                <Icon id="marker"/>
                <div class="footer__link-text">
                  <p>{{`${contact.address.address},`}}</p>
                  <p>{{`${contact.address.postalCode} ${contact.address.city}, ${contact.address.state}`}}</p>
                </div>
              </a>
              <a :href="telephone(contact.phone)" class="footer__link" target="_blank">
                <Icon id="phone"/>
                <span class="footer__link-text">{{contact.phone}}</span>
              </a>
              <a :href="`mailto:${contact.email.toLowerCase()}`" class="footer__link" target="_blank">
                <Icon id="mail"/>
                <span class="footer__link-text">{{contact.email}}</span>
              </a>
              <LangRouterLink :to="{name: 'faq'}" class="footer__link">
                <Icon id="question"/>
                <span class="footer__link-text">{{$t("faq.title")}}</span>
              </LangRouterLink>
            </div>
        </div>
        <div>
          <h2 class="footer__title">{{$t("footer.follow")}}</h2>
          <div class="footer__media-group">
            <a class="footer__media" :href="media.link" v-for="(media, mediaKey) in medias" :key="mediaKey" :title="capitalize(media.name)">
              <Icon :id="media.name"/>
            </a>
          </div>
        </div>
        <Button :link="contact.maestro.link" :label="contact.maestro.title" class="--footer"/>
      </div>
    </div>
    <div class="footer__bottom">
      <p>{{ $t("footer.copyright") }}</p>
      <a v-if="$beet.options.contact.privacy.link" :href="$beet.options.contact.privacy.link" target="_blank" rel="nofollow">
        {{ $beet.options.contact.privacy.label }}
      </a>
      <a href="https://boitebeet.com/" target="_blank">
        <RawHtml :html="$t('footer.design')"></RawHtml>
      </a>
    </div>
  </footer>
</template>

<script>
import {Icon, RawHtml} from "@/components/global";
import LangRouterLink from "@/components/global/LangRouterLink";
import {Button, RightAddressLink} from "@/components/small";

export default {
  name: "Footer",
  components: {Button, RightAddressLink, LangRouterLink, Icon, RawHtml},
  data() {
    return {
      map: require('@/assets/images/map.jpg'),
      marker: require('@/assets/icons/marker-full.svg')
    }
  },
  computed: {
    contact() {
      return {
        address: {
          address: this.$beet.options.contact.address,
          city: this.$beet.options.contact.city,
          link: this.$beet.options.contact.mapLink,
          postalCode: this.$beet.options.contact.postalCode,
          state: this.$beet.options.contact.state
        },
        email: this.$beet.options.contact.email,
        maestro: {
          link: this.$beet.options.contact.maestro.link.toString(),
          title: this.$beet.options.contact.maestro.label.toString()
        },
        phone: this.$beet.options.contact.phone
      }
    },
    medias() {
      return [
        {
          link: this.$beet.options.contact.facebook,
          name: "facebook",
        },
        {
          link: this.$beet.options.contact.instagram,
          name: "instagram",
        }
      ]
    }
  },
  methods: {
    capitalize(word) {
      return `${word.charAt(0).toUpperCase()}${word.slice(0,1)}`
    },
    telephone(number) {
      return `tel:${number.replace('(', '').replace(')', '').replace(' ', '-')}`
    }
  }
}
</script>
