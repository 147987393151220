<template>
  <section  class="section --beige --restaurants">
    <TitleBlock :content="content.titleBlock" class="--restaurant"/>
    <ul class="restaurant__group">
      <RestaurantCard v-for="(restaurant, restaurantKey) in content.restaurants" :key="restaurantKey" :content="restaurant"/>
    </ul>
  </section>
</template>

<script>
import {TitleBlock} from "@/components/blocks";
import {RestaurantCard} from "@/components/cards";
export default {
  name: "RestaurantsSection",
  components: {RestaurantCard, TitleBlock},
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
