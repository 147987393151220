export default {
  "faq": {
    "title": "Frequently Asked Questions",
    "subtitle": "Find answers to your questions"
  },
  "footer": {
    "contact": "Contact Information",
    "follow": "Follow Us",
    "copyright": "Copyright H�tel Boutique Ophelia, 2022 - All Rights Reserved",
    "design": "Design and development by <b>Beet</b>"
  },
  "global": {
    "navigation": {
      "contact": "Contact",
      "home": "Home",
      "hotel": "Hotel",
      "packages": "Packages and offers",
      "rooms": "Rooms and suites",
      "services": "Services",
    },
    "price": 'Starting from PRICE$ per night',
    "reserve": "Reserve"
  },
  "home": {
    "VisitTheWebsite": "Visit the website"
  },
  "notFound": {
    "return": 'Return to home page',
    "subtitle": "The page you are looking for does not exist. It may have been moved or completely deleted. Return to the home page or explore the main navigation tabs.",
    "title": '404 Error'
  },
  "packages": {
    "offer": "Offer",
    "package": "Package"
  },

}
