<template>
  <li class="restaurant__card">
    <a :href="content.website" :title="content.title" class="restaurant__card-image">
      <img :src="content.image" alt="">
    </a>
    <AddressLink :content="addressLink" class="restaurant__card-address"/>
    <a :href="content.website" class="restaurant__link">
      <span>{{$t("home.VisitTheWebsite")}}</span>
      <img :src="arrow" alt="" class="restaurant__arrow">
    </a>
  </li>
</template>

<script>
import {AddressLink} from '@/components/small'

export default {
  name: "RestaurantCard",
  data() {
    return {
      arrow: require('@/assets/icons/arrow.svg')
    }
  },
  components: {
    AddressLink
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    addressLink() {
      return {
        address: this.content.address,
        city: this.content.city,
        link: this.content.mapLink,
        postalCode: this.content.postalCode,
        state: this.content.state
      }
    }
  }
}
</script>
