<template>
  <a :href="content.link" class="hotel__address" target="_blank">
    <address>
      <p>{{`${content.address}`}}</p>
      <p>{{`${content.postalCode} ${content.city}, ${content.state}`}}</p>
    </address>
    <img :src="arrow" alt="" class="hotel__address-icon">
  </a>
</template>

<script>
export default {
  name: "RightAddressLink",
  data() {
    return {
      arrow: require('@/assets/icons/arrow.svg')
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

