<template>
  <section  class="section --about --large">
    <TitleBlock :content="content.titleBlock" class="--center --bottom"/>
    <div class="relative">
      <Button :route="content.route" :label="content.hotelLabel.toString()" class="--hotel"/>
      <img :src="content.image" alt="">

      <RightAddressLink :content="content.contact" class="--end"/>
    </div>
  </section>
</template>

<script>
import TitleBlock from "@/components/blocks/TitleBlock";
import {Button, RightAddressLink} from "@/components/small";

export default {
  name: "AboutSection",
  components: {Button, RightAddressLink, TitleBlock},
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
