<template>
  <section class="detailed-card__group">
    <DetailedCard v-for="(card, cardKey) in cards" :key="cardKey" :content="card" :id="`${packageId}-${cardKey + 1}`"/>
  </section>
</template>

<script>
import {DetailedCard} from '@/components/cards';

export default {
  name: 'DetailedCardSection',
  components: {
    DetailedCard
  },
  props: {
    cards: {
      type: Array,
      required: true
    }
  },
  computed: {
    packageId() {
      return this.$i18n.locale === 'fr' ? 'forfait' : 'package'
    }
  }
}
</script>
