<template>
  <nav class="navbar">
    <DesktopMenu :site-map="siteMap" />
    <MobileMenu :site-map="siteMap" />
  </nav>
</template>

<script>
import {DesktopMenu, MobileMenu} from '@/components/navigation'

export default {
  name: 'Navbar',
  components: {
    DesktopMenu,
    MobileMenu
  },
  computed:{
    reserveLabel() {
      return this.current_lang_upper === 'fr' ? 'Réserver' : 'Book'
    },
    siteMap() {
      return this.$store.getters.getSiteMap.links
    }
  },
  methods:{
    change_lang(){
      const nav = document.getElementById('navbarTogglerMain');
      const navBTN = document.getElementById('navBTN');
      nav.classList.remove('show')
      navBTN.classList.add('collapsed')
    }
  }
}
</script>
