<template>
  <div class="detailed-card">
    <h2 class="title --center --smaller">{{ content.name }}</h2>
    <h3 class="detailed-card__type" v-if="type">{{type}}</h3>
    <div class="detailed-card__content">
      <img :src="content.image" alt="" :class="['detailed-card__image', {'--service': isService}]">
      <div>
        <RawHtml :html="content.description" class="detailed-card__description"/>
        <RawHtml v-if="content.notes" :html="content.notes" class="detailed-card__notes"/>
        <Button v-if="content.reservationLink" :link="content.reservationLink" :label="$t('global.reserve')"/>
      </div>
    </div>
  </div>
</template>

<script>
import {Button} from '@/components/small'
import {RawHtml} from "@/components/global";

export default {
  name: 'DetailedCard',
  components: {Button, RawHtml},
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  computed: {
    isService() {
      return this.content.type ? this.content.type === 'service' : false
    },
    type() {
      switch (this.content.type) {
        case 'package': return this.$t(`packages.${this.content.isPromo ? "offer" : "package"}`)
        case 'service': return this.content.subtitle
        default: return null
      }
    }
  }
}
</script>
