<template>
  <div class="title-block">
    <h2 class="title-block__title">{{content.title}}</h2>
    <h3 class="title-block__subtitle">{{content.subtitle}}</h3>
    <p class="title-block__text">{{content.description}}</p>
  </div>
</template>

<script>
export default {
  name: 'TitleBlock',
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>
