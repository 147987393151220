<template>
  <section class="banner" :style="`background-image: url(${content.image})`">
    <div class="banner__filter"></div>
    <img class="banner__icon" :src="icon" alt="">
    <h1 class="title --hero" v-html="content.title"></h1>
    <p class="subtitle" v-html="content.subtitle"></p>
  </section>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      icon: require('@/assets/icons/texture-black.svg')
    }
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  }
}
</script>

