<template>
<li class="room-card">
  <h2 class="title --center --card">{{content.name}}</h2>
  <p class="room-card__subtitle">{{maxPeople}}</p>
  <div class="room-card__content">
    <div class="room-card__image-container">
      <img :src="content.images[0]" alt="" class="room-card__image">
      <button class="room-card__image-button" @click="openModal">
        <Icon id="image"/>
      </button>
    </div>
    <div>
      <RawHtml :html="content.description" class="room-card__description"/>
      <DetailsDropdown :content="content.moreInfos" :details-label="labels.details" />
      <div class="room-card__buttons">
        <p class="room-card__price">{{price}}</p>
        <Button :link="content.reservationLink" :label="labels.reserve"/>
      </div>
    </div>
  </div>
</li>
</template>

<script>
import {Icon, RawHtml} from "@/components/global";
import {Button, DetailsDropdown} from '@/components/small'

export default {
  name: 'RoomCard',
  components: {
    Button,
    DetailsDropdown,
    Icon,
    RawHtml
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    labels: {
      type: Object,
      required: true
    }
  },
  computed: {
    maxPeople() {
      return this.labels.max.replace('{max}', this.content.maxPeople)
    },
    price() {
      return this.$t("global.price").replace('PRICE', this.content.fromPrice)
    }
  },
  methods: {
    openModal() {
      this.$parent.$emit('openModal', Object.values(this.content.images))
    }
  }
}
</script>
